<template>
    <div>
        <van-tabs color="#3f7c53" @click="tapTabChange">
            <van-tab v-for="t in topTab"
                     :key="t.value"
                     :title="t.title"
            >
            </van-tab>
        </van-tabs>
        <van-tabs color="#3f7c53" @click="tabChange">
            <van-tab v-for="item in tabList"
                     :title="item.title"
                     :key="item.value">
            </van-tab>

            <van-pull-refresh v-model="isLoading"
                              @refresh="onRefresh">
                <van-list v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad">

                    <van-row>
                        <div class="flex align_items_center margin-t-10"
                             v-for="(item,index) in list" :key="index"
                        >
                            <van-col span="7">
                                <div class="flex flex-c">
                                    <div class="title">{{item.deptName}}</div>
                                    <div class="title" v-if="rankingType==='PERSON'">{{item.objName}}</div>
                                </div>

                            </van-col>
                            <van-col span="14">
                                <div class="public-box">
                                    <div>月度</div>
                                    <el-progress
                                         :text-inside="true"
                                         :stroke-width="16"
                                         :percentage="item.stationPct?item.stationPct:0"
                                         track-color="#94A2FE"
                                         :color="item.stationPct >= 0 ? '#4C64FE':'red'"
                                         style="width:3.3rem"
                                         :class="item.stationPct > 100 ?'publicCla margin-l-10':'margin-l-10'"
                                    />
                                </div>
                                <div class="public-box">
                                    <div>季度</div>
                                    <el-progress
                                            :text-inside="true"
                                            :stroke-width="16"
                                            :percentage="item.quarterStationPct?item.quarterStationPct:0"
                                            track-color="#94A2FE"
                                            :color="item.quarterStationPct >= 0 ?'#4C64FE':'red'"
                                            style="width:3.3rem"
                                            :class="item.quarterStationPct>100?'publicCla margin-l-10':'margin-l-10'"
                                    />
                                </div>
                            </van-col>

                            <van-col span="3" class="margin-left">
                                <van-image
                                        v-if="item.stationRanking < 4"
                                        width="30"
                                        height="30"
                                        :src="item.stationRanking===1?gold:
                                          item.stationRanking===2?silver:
                                          item.stationRanking===3?copper:''"
                                        round
                                />

                                <div v-if="item.stationRanking > 3" :style="bgBox" class="background-image">{{item.stationRanking}}</div>
                            </van-col>
                        </div>
                    </van-row>

                </van-list>
            </van-pull-refresh>


        </van-tabs>

    </div>
    
</template>

<script>
    import {getPerformanceAchievement} from '@/utils/goals';
    export default {
        name: "ranking",
        data(){
            return{
                year: new Date().getFullYear(), //获取当前年
                month: new Date().getMonth() + 1, //获取当前月
                tabList:[
                    {title:'新建站点',value:'NewlyStation'},
                    {title:'有效站点',value:'ActiveStation'},
                    {title:'双百站点',value:'DoubleHundred'},
                    {title:'时点余额',value:'PointBalance'},
                    //{ title: '达标站点', value: 'ReachToQs' },
                    {title:'上线站点',value:'onlineStation'}
                ],
                tabEnume: { //tab枚举
                    0: 'NewlyStation',
                    1: 'ActiveStation',
                    2: 'DoubleHundred',
                    3: 'PointBalance',
                    4: 'onlineStation'
                },
                stationType:'NewlyStation',//tab类型
                topTab:[
                    {title:'运营部排名',value:'AREA'},
                    {title:'人员排名',value:'PERSON'},
                ],
                topTabEnume: {
                    0: 'AREA',
                    1: 'PERSON',
                },
                rankingType:'AREA',//排名类型
                deptNo:'',
                gold:require('../../assets/img/ps/gold.png'),
                silver:require('../../assets/img/ps/silver.png'),
                copper:require('../../assets/img/ps/copper.png'),
                bgBox:{
                    backgroundImage: "url(" + require('../../assets/img/ps/rank-bg.png') + ")",
                },
                page:1,
                size:10,
                list:[],
                isLoading:false,
                loading: false,
                finished: false

            }
        },
        methods:{
            onLoad(){
                this.seachData();
            },
            //查询数据
            seachData(){
                let data = {
                    targetDate:this.$route.query.targetDate,//this.year+'/'+this.month,
                    rankingLevel: 'PROVINCE',
                    rankingType:this.rankingType,//地区排名：AREA；人员排名：PERSON
                    objNo:this.$route.query.deptNo,
                    stationType:this.stationType,
                    page:this.page,
                    size:this.size,
                }
                getPerformanceAchievement(data).then(res=>{
                    this.loading = false;
                    if(res.code===200 && res.data.businessCode ===200){
                        this.finished = !res.data.rankList.hasNextPage;
                        if (this.page === 1) {
                            this.list = res.data.rankList.list
                        } else {
                            this.list = this.list.concat(res.data.rankList.list);
                        }
                        if (!this.finished) {
                            this.page++;
                        }
                    }
                    if(!res.data.rankList){//如果没有数据
                        this.finished = true;
                    }
                })
            },

            //切换排名类型
            tapTabChange(value){
                this.rankingType = this.topTabEnume[value];
                this.page = 1;
                this.list = [];
                this.seachData();
            },
            //切换tab类型
            tabChange(value){
                this.stationType = this.tabEnume[value];
                this.page = 1;
                this.list = [];
                this.seachData();
            },
            //下拉刷新
            onRefresh() {
                this.isLoading = false;
                this.list = [];
                this.page = 1;
                this.seachData();
            },

        }
    }
</script>

<style lang="less">
    @import "../../style/goals.less";
    .title{
        padding-left:0.2rem;
        font-weight: 600;
        width: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .public-box{
        display: flex;
        align-items: center;
    }
    .margin-left{
        margin-left: 0.2rem;
    }
    .margin-l-10{
        margin-left: 10px;
    }
    .publicCla .el-progress-bar__inner{
        width: 100% !important;
    }
    .margin-t-10{
        margin-top: 10px;
    }


</style>
